import axios from '@/common/axios'
import qs from 'qs'

export function addBacteriaUseDestroy (data) {
  return axios({
    method: 'post',
    url: '/quality/bacteriaUseDestroy/add',
    data: qs.stringify(data)
  })
}

export function deleteBacteriaUseDestroy (id) {
  return axios({
    method: 'delete',
    url: '/quality/bacteriaUseDestroy/delete/' + id
  })
}

export function updateBacteriaUseDestroy (data) {
  return axios({
    method: 'put',
    url: '/quality/bacteriaUseDestroy/update',
    data: qs.stringify(data)
  })
}

export function selectBacteriaUseDestroyInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/bacteriaUseDestroy/info/' + id
  })
}

export function selectBacteriaUseDestroyList (query) {
  return axios({
    method: 'get',
    url: '/quality/bacteriaUseDestroy/list',
    params: query
  })
}
