<template>
  <div id="bacteriaUseDestroy">
    <el-dialog
      :title="bacteriaUseDestroyFormTitle"
      width="1200px"
      :visible.sync="bacteriaUseDestroyDialogVisible"
      :close-on-click-modal="false"
      @close="bacteriaUseDestroyDialogClose"
    >
      <el-form
        ref="bacteriaUseDestroyFormRef"
        :model="bacteriaUseDestroyForm"
        :rules="bacteriaUseDestroyFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="菌种编号" prop="bacteriaNo">
              <el-input v-model="bacteriaUseDestroyForm.bacteriaNo" placeholder="请输入菌种编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="菌种名称" prop="bacteriaName">
              <el-input v-model="bacteriaUseDestroyForm.bacteriaName" placeholder="请输入菌种名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="使用日期" prop="useDate">
              <el-date-picker v-model="bacteriaUseDestroyForm.useDate" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="用途" prop="purpose">
              <el-input v-model="bacteriaUseDestroyForm.purpose" placeholder="请输入用途" clearable />
            </el-form-item>
          </el-col>
           <el-col :span="8">
            <el-form-item label="使用人" prop="user">
              <el-input v-model="bacteriaUseDestroyForm.user" placeholder="请输入使用人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="保管人" prop="custodian">
              <el-input v-model="bacteriaUseDestroyForm.custodian" placeholder="请输入保管人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="销毁日期" prop="destructionDate">
              <el-date-picker v-model="bacteriaUseDestroyForm.destructionDate" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="销毁原因" prop="destructionReason">
              <el-input v-model="bacteriaUseDestroyForm.destructionReason" placeholder="请输入销毁原因" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="销毁人" prop="destroyer">
              <el-input v-model="bacteriaUseDestroyForm.destroyer" placeholder="请输入销毁人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="监销人" prop="supervisor">
              <el-input v-model="bacteriaUseDestroyForm.supervisor" placeholder="请输入监销人" clearable />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="bacteriaUseDestroyDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="bacteriaUseDestroyFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="设备编号">
        <el-input v-model="searchForm.deviceNo" placeholder="请输入设备编号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="bacteriaUseDestroyPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="bacteriaNo" label="菌种编号" />
      <el-table-column prop="bacteriaName" label="菌种名称" />
      <el-table-column prop="useDate" label="使用日期" />
      <el-table-column prop="purpose" label="用途" />
      <el-table-column prop="user" label="使用人" />
      <el-table-column prop="custodian" label="保管人" />
      <el-table-column prop="destructionDate" label="销毁日期" />
      <el-table-column prop="destructionReason" label="销毁原因" />
      <el-table-column prop="destroyer" label="销毁人" />
      <el-table-column prop="supervisor" label="监销人" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="bacteriaUseDestroyPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addBacteriaUseDestroy, deleteBacteriaUseDestroy, updateBacteriaUseDestroy, selectBacteriaUseDestroyInfo, selectBacteriaUseDestroyList } from '@/api/quality/bacteriaUseDestroy'

export default {
  data () {
    return {
      bacteriaUseDestroyDialogVisible: false,
      bacteriaUseDestroyFormTitle: '',
      bacteriaUseDestroyForm: {
        id: '',
        bacteriaNo: '',
        bacteriaName: '',
        useDate: '',
        purpose: '',
        user: '',
        custodian: '',
        destructionDate: '',
        destructionReason: '',
        destroyer: '',
        destroyer: ''
      },
      bacteriaUseDestroyFormRules: {
        bacteriaNo: [{ required: true, message: '菌种编号不能为空', trigger: ['blur', 'change']}]
      },
      bacteriaUseDestroyPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        deviceNo: ''
      }
    }
  },
  created () {
    selectBacteriaUseDestroyList(this.searchForm).then(res => {
      this.bacteriaUseDestroyPage = res
    })
  },
  methods: {
    bacteriaUseDestroyDialogClose () {
      this.$refs.bacteriaUseDestroyFormRef.resetFields()
      this.bacteriaUseDestroyForm.id = ''
    },
    bacteriaUseDestroyFormSubmit () {
      if (this.bacteriaUseDestroyFormTitle === '菌种使用及销毁') {
        this.bacteriaUseDestroyDialogVisible = false
        return
      }
      this.$refs.bacteriaUseDestroyFormRef.validate(async valid => {
        if (valid) {
          if (this.bacteriaUseDestroyFormTitle === '新增菌种使用及销毁') {
            await addBacteriaUseDestroy(this.bacteriaUseDestroyForm)
          } else if (this.bacteriaUseDestroyFormTitle === '修改菌种使用及销毁') {
            await updateBacteriaUseDestroy(this.bacteriaUseDestroyForm)
          }
          this.bacteriaUseDestroyPage = await selectBacteriaUseDestroyList(this.searchForm)
          this.bacteriaUseDestroyDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.bacteriaUseDestroyFormTitle = '新增菌种使用及销毁'
      this.bacteriaUseDestroyDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteBacteriaUseDestroy(row.id)
        if (this.bacteriaUseDestroyPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.bacteriaUseDestroyPage = await selectBacteriaUseDestroyList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.bacteriaUseDestroyFormTitle = '修改菌种使用及销毁'
      this.bacteriaUseDestroyDialogVisible = true
      this.selectBacteriaUseDestroyById(row.id)
    },
    handleInfo (index, row) {
      this.bacteriaUseDestroyFormTitle = '菌种使用及销毁详情'
      this.bacteriaUseDestroyDialogVisible = true
      this.selectBacteriaUseDestroyById(row.id)
    },
    selectBacteriaUseDestroyById (id) {
      selectBacteriaUseDestroyInfo(id).then(res => {
        this.bacteriaUseDestroyForm.id = res.id
        this.bacteriaUseDestroyForm.bacteriaNo = res.bacteriaNo
        this.bacteriaUseDestroyForm.bacteriaName = res.bacteriaName
        this.bacteriaUseDestroyForm.useDate = res.useDate
        this.bacteriaUseDestroyForm.purpose = res.purpose
        this.bacteriaUseDestroyForm.user = res.user
        this.bacteriaUseDestroyForm.custodian = res.custodian
        this.bacteriaUseDestroyForm.destructionDate = res.destructionDate
        this.bacteriaUseDestroyForm.destructionReason = res.destructionReason
        this.bacteriaUseDestroyForm.destroyer = res.destroyer
        this.bacteriaUseDestroyForm.supervisor = res.supervisor
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectBacteriaUseDestroyList(this.searchForm).then(res => {
        this.bacteriaUseDestroyPage= res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectBacteriaUseDestroyList(this.searchForm).then(res => {
        this.bacteriaUseDestroyPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectBacteriaUseDestroyList(this.searchForm).then(res => {
        this.bacteriaUseDestroyPage = res
      })
    }
  }
}
</script>

<style>
</style>
